export enum EnvironmentType {
  Development = 'Development',
  Testing = 'Testing',
  Production = 'Production'
}

export enum UserPageTabIndexes {
  User,
  UserAssign
}

/**
 * Enum to identify report type
 */
export enum PrintFormat {
  SaleOffer,
  SaleOrder,
  VoucherCancellation,
  Invoices,
  PinPadInvoices,
  CreditNote,
  ReceivedPayment,
  CashClosing,
  ReprintSaleOffers,
  ReprintInvoices,
  ReprintSaleOrders,
  GoodsReceipt,
  GoodsIssue,
  GoodsReceiptPO,
  GoodsReturn,
  PurchaseOrder,
  APInvoice,
  InventoryTransferRequest,
  InventoryTransfer,
  OutgoingPayment,
  CancelPayment,
  ArDownPayment,
  PinpadDownPayment,
  ReprintArDownPayment,
  ReprintReserveInvoice,
  ReserveInvoice,
  DeliveryNotes,
  Preliminary,
  ReprintPreliminary,
  ReprintDeliveryNotes,
  ApDownPayment,
  PinpadApDownPayment,
  ReprintApDownPayment,
  PinpadAPInvoice,
  ReprintCreditNotes,
  ReprintTransferRequest,
  ReprintPurchaseOrder,
  InventoryCountings
}

/**
 * Represent code of settings
 */
export enum SettingCode {
  PrintFormat = 'PrintFormat',
  Account = 'Account',
  ValidateInventory = 'ValidateInventory',
  ReportManager = 'ReportManager',
  Route = 'Route',
  DefaultBusinessPartner = 'DefaultBusinessPartner',
  Decimal = 'Decimal',
  Payment = 'Payment',
  LineMode = 'LineMode',
  AdjustmentInventory = 'AdjustmentInventory',
  MemoryInvoice = 'MemoryInvoice',
  Margin = 'Margin',
  PatternTypeahead = 'PatternTypeahead',
  Points = 'Points',
  FieldsConfiguredSAP = 'FieldsConfiguredSAP',
  MobileAppConfiguration = 'MobileAppConfiguration',
  SchedulingConfiguration = 'SchedulingConfiguration',
  EventViewer = 'EventViewer',
  GuideTour = 'GuideTour',
  FieldsInvoice = 'FieldsInvoice',
  ShareFolder = 'ShareFolder',
  Recaptcha = 'Recaptcha',
  FESerieDocument = 'FESerieDocument',
  FieldsPurchaseInvoice = 'FieldsPurchaseInvoice',
  UdfGroup = 'UdfGroup',
  ValidateAttachments = 'ValidateAttachments',
  AuthorizationNotifications = 'AuthorizationNotifications',
  WarehousesConfig = 'Almacen de origen de solicitudes de traslado',
}

/**
 * Enum get table name of document type
 */
export enum DocumentType {
  Quotations = 'OQUT',
  Orders = 'ORDR',
  Invoices = 'OINV',
  Purchase = 'OPDN',
  PurchaseOrder = 'OPOR',
  PurchaseReturns = 'ORPD',
  PurchaseInvoice = 'OPCH',
  IncomingPayments = 'ORCT',
  ArDownPayments = 'ODPI',
  DeliveryNotes = 'ODLN',
  Draft = 'ODRF',
  OutgoingPayment ='OVPM',
  APDownPayments = 'ODPO',
  CreditNotes = 'ORIN'
}
/**
 * Enum get table code of document type
 */
export enum DocumentTypeCode
{
  IncomingPayments = 24,
  CreditNotes = 14,
  InvoicesFE = 13,
  InvoicesTE = -13,
  Items = 4,
  SalesOrders = 17,
  SalesQuotations = 23,
  BusinessPartners = 2,
  Supplier = -2,
  PurchaseInvoice = 18,
  OutgoingPayment = 46,
  GoodReceiptPO = 20,
  GoodReturn = 21,
  GoodReceipt = 59,
  GoodIssue = 60,
  Delivery = 15,
  PurchaseOrder = 22,
  ArDownPayment = 203,
  ApDownPayment = 204,
  Drafts = 112,
  InventoryTransferRequest = 1250000001,
  InventoryTransfer = 67
}

/**
 * Enum to preloaded document
 */
export enum PreloadedDocumentActions {
  EDIT = 'edit',
  COPY = 'copy',
  DUPLICATE = 'duplicate',
  CREATE_FROM_DRAFT = 'draft'
}

export enum TypeInvoices {
  INVOICE = 'Invoice',
  RESERVE_INVOICE = 'ReserveInvoice'
}

/**
 * Enum to identifier document origin
 */
export enum CopyFrom {
  OPOR = 22,
  OINV = 13,
  ODPI = 203,
  OQUT = 23,
  ORDR = 17,
  OPDN = 20,
  ORPD = 21,
  OPCH = 18,
  ODPO = 204
}


/**
 * Identificar si item se maneja con series y lotes
 */
export enum ItemSerialBatch {
  NA = 1,
  Serie,
  Lote,

}

export enum ItemsClass {
  Service = '1',
  Material = '2'
}


export enum ViewBatches {
  FACTURACION = 1,
  TRANSFER_INVENTORY = 2
}

export enum RouteStatus {
  INACTIVE,
  ACTIVATED,
  FINISHED,
  CLOSED
}

export enum RouteType {
  ORDER,
  DELIVERY
}

export enum RouteCheckTypes {
  DESTINATION,
  FAILED_EXIT,
  SUCCESS_EXIT,
  AUTOMATIC_POINT,
  ARRIVAL,
  NOT_LINKED_TO_ROUTE,
  ROUTE_START,
  END_OF_ROUTE
}

export enum DocumentSyncStatus {
  All = '',
  InQueue = 'Q',
  Processing = 'P',
  Errors = 'E',
  Success = 'S'
}

export enum Event {
  All = "",
  Information = "I",
  Success = "S",
  Warning = "W",
  Error = "E"
}

export enum DocumentSyncTypes {
  All = '',
  SaleOrder = 'OR',
  SaleQuotation = 'OF',
  Invoice = 'IV',
  InvoiceWithPayment = 'IP',
  IncomingPayment = 'PA'
}

export enum ModalSalesInfo {
  ACEPT = 1,
  REIMPRIMIR = 2
}

export enum Titles {
  Factura = 'Factura',
  Orden = 'Orden',
  Cotizacion = 'Cotización',
  NC = 'Nota',
  ArDownPayment = 'Anticipo',
  Entrega = 'Entrega',
  ReserveInvoice = 'Factura reserva',
  Draft = 'Documento preliminar',
  ApDownPayment = 'Factura anticipo',
  ApFactura= 'Factura proveedor'
}

export enum ListMaterial {
  iSalesTree = 'iSalesTree',
  iIngredient = 'iIngredient',
  iNotATree = 'iNotATree'
}

/**
 * Enum to identify payment invoices
 */
export enum PaymentInvoiceType {
  it_Invoice = 'it_Invoice',
  it_DownPayment = 'it_DownPayment',
  it_PurchaseInvoice = 'it_PurchaseInvoice',
  it_PurchaseDownPayment = 'it_PurchaseDownPayment'
}

export enum BPMode {
  CREACION = 'Creacion',
  EDICION = 'Edicion'
}

export enum Address {
  BILL = 'bo_BillTo',
  SHIL = 'bo_ShipTo'
}

export enum LineStatus {
  bost_Close = 'bost_Close',
  bost_Open = 'bost_Open'
}

export enum PPStoredTransaction_State {
  StateType = 'CNL' //CANCEL
}

export enum PurchaseTypeDocuments {
  purchaseDeliveryNotes = 'PurchaseDeliveryNotes',
  purchaseInvoices = 'PurchaseInvoices',
  PurchaseOrders = 'PurchaseOrders',
  Draft = 'Draft',
  PurchaseDownPayments = 'PurchaseDownPayments',
  PurchaseReturns = 'PurchaseReturns'
}

/**
 * Enum to assign controller name
 */
export enum ControllerName {
  goodReceipt = 'PurchaseDeliveryNotes',
  order = 'PurchaseOrders',
  purchaseInvoice = 'PurchaseInvoices',
  Draft ='Draft',
  APDownPayments = 'PurchaseDownPayments',
  CreditNotes = 'CreditNotes',
  Invoices = 'Invoices',
  InventoryTransferRequests= 'InventoryTransferRequests',
  PurchaseReturns = 'PurchaseReturns',
  InventoryGenExits = 'InventoryGenExits',
  InventoryGenEntries = 'InventoryGenEntries',
  StockTransfers = 'StockTransfers'
}

/**
 * Object type serial
 */
export enum ObjectType {
  Items = 4,
  BusinessPartnerVendor = -2,
  BusinessPartnerCustomer = 2
}

/**
 * Enum to identify payment term
 */
export enum Payterm {
  Credit = 1,
  Counted
}

/**
 * Represents filter values per view.
 */
export enum ItemsFilterType {
  InvntItem = 'InvntItem',
  SellItem = 'SellItem',
  PrchseItem = 'PrchseItem'
}

/**
 * Represents type of device where the document was created
 */
export enum TypeDevice {
  MOVIL = 'M',
  WEB = 'W'
}

/**
 * Represents type recurrence activities
 */
export enum RecurrencePattern {
  rpNone = 'rpNone',
  rpDaily = 'rpDaily',
  rpWeekly = 'rpWeekly',
  rpMonthly = 'rpMonthly',
  rpAnnually = 'rpAnnually'
}

export enum BoYesNo{
  tYES = 'tYES',
  tNO = 'tNO'
}

/**
 * This enum represents object SAP
 */
export enum ObjectSAP{
  ODRF = 112,
  OITM = 4
}

/**
 * This enum represents the types of the option
 */
export enum SubOption{
  roByDate = 'roByDate',
  roByWeekDay = 'roByWeekDay'
}

/**
 * This enum represents the types of the option recurrences
 */
export enum EndType{
  etNoEndDate = 'etNoEndDate',
  etByCounter = 'etByCounter',
  etByDate = 'etByDate'
}

/**
 * This enum represents the types of the recurrence sequence
 */
export enum RecurrenceSequenceSpecifier{
  rsFirst = 'rsFirst',
  rsFourth = 'rsFourth',
  rsThird = 'rsThird',
  rsLast = 'rsLast',
  rsSecond = 'rsSecond'
}

/**
 * This enum represents the types of the series
 */
export enum SerieTypes{
  Offline = 1
}

/**
 * This enum represents the types recurrence
 */
export enum RecurrenceDayOfWeek {
  rdowSun = 0,
  rdowMon = 1,
  rdowTue = 2,
  rdowWed = 3,
  rdowThu = 4,
  rdowFri = 5,
  rdowSat = 6,
  rdowDay = 7,
  rdowWeekDay = 8,
  rdowWeekendDay = 9
}

/*
 * This enum represents the keys of menu nodes
 */
export enum MenuNodesKey{
  Reports = 'I-43',
  Logout = 'J-44',
  ReportsCategory = 'I'
}

/**
 * Represent type of view sales or purchase
 */
export enum ViewType {
  Sales = "Sales",
  Purchase = "Purchase"
}


/**
 * Represent permission to edit document dates
 */
export enum PermissionEditDocumentsDates {
  //Quotations
  SalesDocumentsQuotationsChangeDocDate = "Sales_Documents_Quotations_ChangeDocDate",
  SalesDocumentsQuotationsChangeDocDueDate = "Sales_Documents_Quotations_ChangeDocDueDate",
  SalesDocumentsQuotationsChangeTaxDate = "Sales_Documents_Quotations_ChangeTaxDate",

  //Orders
  SalesDocumentsOrdersChangeDocDate = "Sales_Documents_Orders_ChangeDocDate",
  SalesDocumentsOrdersChangeDocDueDate = "Sales_Documents_Orders_ChangeDocDueDate",
  SalesDocumentsOrdersChangeTaxDate = "Sales_Documents_Orders_ChangeTaxDate",

  //Delivery
  SalesDocumentsDeliveryChangeDocDate = "Sales_Documents_Delivery_ChangeDocDate",
  SalesDocumentsDeliveryChangeDocDueDate = "Sales_Documents_Delivery_ChangeDocDueDate",
  SalesDocumentsDeliveryChangeTaxDate = "Sales_Documents_Delivery_ChangeTaxDate",

  //Down Payments
  SalesDocumentsDownPaymentsChangeDocDate = "Sales_Documents_DownPayments_ChangeDocDate",
  SalesDocumentsDownPaymentsChangeDocDueDate = "Sales_Documents_DownPayments_ChangeDocDueDate",
  SalesDocumentsDownPaymentsChangeTaxDate = "Sales_Documents_DownPayments_ChangeTaxDate",

  //Invoice
  SalesDocumentsInvoicesChangeDocDate = "Sales_Documents_Invoices_ChangeDocDate",
  SalesDocumentsInvoicesChangeDocDueDate = "Sales_Documents_Invoices_ChangeDocDueDate",
  SalesDocumentsInvoicesChangeTaxDate = "Sales_Documents_Invoices_ChangeTaxDate",

  //Reserve invoice
  SalesDocumentsReserveInvoiceChangeDocDate = "Sales_Documents_ReserveInvoice_ChangeDocDate",
  SalesDocumentsReserveInvoiceChangeDocDueDate = "Sales_Documents_ReserveInvoice_ChangeDocDueDate",
  SalesDocumentsReserveInvoiceChangeTaxDate = "Sales_Documents_ReserveInvoice_ChangeTaxDate",

  //Credit memo
  SalesDocumentsCreditMemoChangeDocDate = "Sales_Documents_CreditMemo_ChangeDocDate",
  SalesDocumentsCreditMemoChangeDocDueDate = "Sales_Documents_CreditMemo_ChangeDocDueDate",
  SalesDocumentsCreditMemoChangeTaxDate = "Sales_Documents_CreditMemo_ChangeTaxDate",

  //Purchase order
  PurchasesDocumentsOrderChangeDocDate = "Purchases_Documents_Order_ChangeDocDate",
  PurchasesDocumentsOrderChangeDocDueDate = "Purchases_Documents_Order_ChangeDocDueDate",
  PurchasesDocumentsOrderChangeTaxDate = "Purchases_Documents_Order_ChangeTaxDate",

  //Good receipt
  PurchasesDocumentsGoodReceiptChangeDocDate = "Purchases_Documents_GoodReceipt_ChangeDocDate",
  PurchasesDocumentsGoodReceiptChangeDocDueDate = "Purchases_Documents_GoodReceipt_ChangeDocDueDate",
  PurchasesDocumentsGoodReceiptChangeTaxDate = "Purchases_Documents_GoodReceipt_ChangeTaxDate",

  //Return Good
  PurchasesDocumentsReturnGoodChangeDocDate = "Purchases_Documents_ReturnGood_ChangeDocDate",
  PurchasesDocumentsReturnGoodChangeDocDueDate = "Purchases_Documents_ReturnGood_ChangeDocDueDate",
  PurchasesDocumentsReturnGoodChangeTaxDate = "Purchases_Documents_ReturnGood_ChangeTaxDate",

  //Purchase invoice
  PurchasesDocumentsInvoiceChangeDocDate = "Purchases_Documents_Invoice_ChangeDocDate",
  PurchasesDocumentsInvoiceChangeDocDueDate = "Purchases_Documents_Invoice_ChangeDocDueDate",
  PurchasesDocumentsInvoiceChangeTaxDate = "Purchases_Documents_Invoice_ChangeTaxDate",

  //Purchase invoice
  PurchasesDocumentsDownPaymentsChangeDocDate = "Purchases_Documents_DownPayments_ChangeDocDate",
  PurchasesDocumentsDownPaymentsChangeDocDueDate = "Purchases_Documents_DownPayments_ChangeDocDueDate",
  PurchasesDocumentsDownPaymentsChangeTaxDate = "Purchases_Documents_DownPayments_ChangeTaxDate",

  //Inventory entry
  InventoryDocumentsEntryChangeDocDate = "Inventory_Documents_Entry_ChangeDocDate",
  InventoryDocumentsEntryChangeDocDueDate = "Inventory_Documents_Entry_ChangeDocDueDate",
  InventoryDocumentsEntryChangeTaxDate = "Inventory_Documents_Entry_ChangeTaxDate",

  //Inventory output
  InventoryDocumentsOutputChangeDocDate = "Inventory_Documents_Output_ChangeDocDate",
  InventoryDocumentsOutputChangeDocDueDate = "Inventory_Documents_Output_ChangeDocDueDate",
  InventoryDocumentsOutputChangeTaxDate = "Inventory_Documents_Output_ChangeTaxDate",

  //Transfer request
  InventoryDocumentsTransferRequestChangeDocDate = "Inventory_Documents_TransferRequest_ChangeDocDate",
  InventoryDocumentsTransferRequestChangeDocDueDate = "Inventory_Documents_TransferRequest_ChangeDocDueDate",
  InventoryDocumentsTransferRequestChangeTaxDate = "Inventory_Documents_TransferRequest_ChangeTaxDate",

  //Stock transfer
  InventoryDocumentsStockTransferChangeDocDate = "Inventory_Documents_StockTransfer_ChangeDocDate",
  InventoryDocumentsStockTransferChangeTaxDate = "Inventory_Documents_StockTransfer_ChangeTaxDate",
}

export enum PermissionViewColumnsItems {
  //Quotattions
  Sales_Documents_Quotations_ViewCCost = "Sales_Documents_Quotations_ViewCCost",
  Sales_Documents_Quotations_ViewDiscount = "Sales_Documents_Quotations_ViewDiscount",
  Sales_Documents_Quotations_ViewTaxOnly = "Sales_Documents_Quotations_ViewTaxOnly",
  Sales_Documents_Quotations_ViewTaxCode = "Sales_Documents_Quotations_ViewTaxCode",
  Sales_Documents_Quotations_ViewWhsName = "Sales_Documents_Quotations_ViewWhsName",
  Sales_Documents_Quotations_ViewUoMEntry = "Sales_Documents_Quotations_ViewUoMEntry",


  //Orders
  Sales_Documents_Orders_ViewCCost = "Sales_Documents_Orders_ViewCCost",
  Sales_Documents_Orders_ViewDiscount = "Sales_Documents_Orders_ViewDiscount",
  Sales_Documents_Orders_ViewTaxOnly = "Sales_Documents_Orders_ViewTaxOnly",
  Sales_Documents_Orders_ViewTaxCode = "Sales_Documents_Orders_ViewTaxCode",
  Sales_Documents_Orders_ViewWhsName = "Sales_Documents_Orders_ViewWhsName",
  Sales_Documents_Orders_ViewUoMEntry = "Sales_Documents_Orders_ViewUoMEntry",

  //Invoices
  Sales_Documents_Invoices_ViewCCost = "Sales_Documents_Invoices_ViewCCost",
  Sales_Documents_Invoices_ViewDiscount = "Sales_Documents_Invoices_ViewDiscount",
  Sales_Documents_Invoices_ViewTaxOnly = "Sales_Documents_Invoices_ViewTaxOnly",
  Sales_Documents_Invoices_ViewTaxCode = "Sales_Documents_Invoices_ViewTaxCode",
  Sales_Documents_Invoices_ViewWhsName = "Sales_Documents_Invoices_ViewWhsName",
  Sales_Documents_Invoices_ViewUoMEntry = "Sales_Documents_Invoices_ViewUoMEntry",


  //ReserveInvoice
  Sales_Documents_ReserveInvoice_ViewCCost = "Sales_Documents_ReserveInvoice_ViewCCost",
  Sales_Documents_ReserveInvoice_ViewDiscount = "Sales_Documents_ReserveInvoice_ViewDiscount",
  Sales_Documents_ReserveInvoice_ViewTaxOnly = "Sales_Documents_ReserveInvoice_ViewTaxOnly",
  Sales_Documents_ReserveInvoice_ViewTaxCode = "Sales_Documents_ReserveInvoice_ViewTaxCode",
  Sales_Documents_ReserveInvoice_ViewWhsName = "Sales_Documents_ReserveInvoice_ViewWhsName",
  Sales_Documents_ReserveInvoice_ViewUoMEntry = "Sales_Documents_ReserveInvoice_ViewUoMEntry",

  //DownPayments
  Sales_Documents_DownPayments_ViewCCost = "Sales_Documents_DownPayments_ViewCCost",
  Sales_Documents_DownPayments_ViewDiscount = "Sales_Documents_DownPayments_ViewDiscount",
  Sales_Documents_DownPayments_ViewTaxOnly = "Sales_Documents_DownPayments_ViewTaxOnly",
  Sales_Documents_DownPayments_ViewTaxCode = "Sales_Documents_DownPayments_ViewTaxCode",
  Sales_Documents_DownPayments_ViewWhsName = "Sales_Documents_DownPayments_ViewWhsName",
  Sales_Documents_DownPayments_ViewUoMEntry = "Sales_Documents_DownPayments_ViewUoMEntry",

  //Delivery
  Sales_Documents_Delivery_ViewCCost = "Sales_Documents_Delivery_ViewCCost",
  Sales_Documents_Delivery_ViewDiscount = "Sales_Documents_Delivery_ViewDiscount",
  Sales_Documents_Delivery_ViewTaxOnly = "Sales_Documents_Delivery_ViewTaxOnly",
  Sales_Documents_Delivery_ViewTaxCode = "Sales_Documents_Delivery_ViewTaxCode",
  Sales_Documents_Delivery_ViewWhsName = "Sales_Documents_Delivery_ViewWhsName",
  Sales_Documents_Delivery_ViewUoMEntry = "Sales_Documents_Delivery_ViewUoMEntry",

  //CreditMemo
  Sales_Documents_CreditMemo_ViewCCost = "Sales_Documents_CreditMemo_ViewCCost",
  Sales_Documents_CreditMemo_ViewDiscount = "Sales_Documents_CreditMemo_ViewDiscount",
  Sales_Documents_CreditMemo_ViewTaxOnly = "Sales_Documents_CreditMemo_ViewTaxOnly",
  Sales_Documents_CreditMemo_ViewTaxCode = "Sales_Documents_CreditMemo_ViewTaxCode",
  Sales_Documents_CreditMemo_ViewWhsName = "Sales_Documents_CreditMemo_ViewWhsName",
  Sales_Documents_CreditMemo_ViewUoMEntry = "Sales_Documents_CreditMemo_ViewUoMEntry",


  //Inventory Recount
  Inventory_Recount_DeleteLine = "Inventory_Counting_DeleteLine",
  Inventory_Recount_ViewUoMCode = "Inventory_Counting_ViewUoMCode",
  Inventory_Recount_ViewBoolCounted = "Inventory_Counting_ViewBoolCounted",
  Inventory_Recount_ViewVariance = "Inventory_Counting_ViewVariance",
  Inventory_Recount_ViewWarehouseQuantity = "Inventory_Counting_ViewWarehouseQuantity",
  Inventory_Recount_ViewUoMCountedQuantity = "Inventory_Counting_ViewUoMCountedQuantity",
  Inventory_Recount_ViewWarehouseCode = "Inventory_Counting_ViewWarehouseCode",

  //Transfer request
  Inventory_Documents_TransferRequest_ViewFromNameWhsCode = "Inventory_Documents_TransferRequest_ViewFromNameWhsCode",
  Inventory_Documents_TransferRequest_ViewToNameWarehouse= "Inventory_Documents_TransferRequest_ViewToNameWarehouse",

  //Inventory StockTransfer
  Inventory_Documents_StockTransfer_ViewFromNameWhsCode = "Inventory_Documents_StockTransfer_ViewFromNameWhsCode",
  Inventory_Documents_StockTransfer_ViewBinAbsOrigin = "Inventory_Documents_StockTransfer_ViewBinAbsOrigin",
  Inventory_Documents_StockTransfer_ViewToNameWarehouse = "Inventory_Documents_StockTransfer_ViewToNameWarehouse",
  Inventory_Documents_StockTransfer_ViewBinAbsDestino = "Inventory_Documents_StockTransfer_ViewBinAbsDestino",


}

export enum PermissionViewColumnsItemsPurchases {
  //Orders
  Purchases_Documents_Order_ViewCCost = "Purchases_Documents_Order_ViewCCost",
  Purchases_Documents_Order_ViewDiscount = "Purchases_Documents_Order_ViewDiscount",
  Purchases_Documents_Order_ViewTaxOnly = "Purchases_Documents_Order_ViewTaxOnly",
  Purchases_Documents_Order_ViewTaxCode = "Purchases_Documents_Order_ViewTaxCode",
  Purchases_Documents_Order_ViewWhsName = "Purchases_Documents_Order_ViewWhsName",
  Purchases_Documents_Order_ViewUoMEntry = "Purchases_Documents_Order_ViewUoMEntry",

  //ReturnGood
  Purchases_Documents_ReturnGood_ViewCCost = "Purchases_Documents_ReturnGood_ViewCCost",
  Purchases_Documents_ReturnGood_ViewDiscount = "Purchases_Documents_ReturnGood_ViewDiscount",
  Purchases_Documents_ReturnGood_ViewTaxOnly = "Purchases_Documents_ReturnGood_ViewTaxOnly",
  Purchases_Documents_ReturnGood_ViewTaxCode = "Purchases_Documents_ReturnGood_ViewTaxCode",
  Purchases_Documents_ReturnGood_ViewWhsName = "Purchases_Documents_ReturnGood_ViewWhsName",
  Purchases_Documents_ReturnGood_ViewUoMEntry = "Purchases_Documents_ReturnGood_ViewUoMEntry",

  //Invoice
  Purchases_Documents_Invoice_ViewCCost = "Purchases_Documents_Invoice_ViewCCost",
  Purchases_Documents_Invoice_ViewDiscount = "Purchases_Documents_Invoice_ViewDiscount",
  Purchases_Documents_Invoice_ViewTaxOnly = "Purchases_Documents_Invoice_ViewTaxOnly",
  Purchases_Documents_Invoice_ViewTaxCode = "Purchases_Documents_Invoice_ViewTaxCode",
  Purchases_Documents_Invoice_ViewWhsName = "Purchases_Documents_Invoice_ViewWhsName",
  Purchases_Documents_Invoice_ViewUoMEntry = "Purchases_Documents_Invoice_ViewUoMEntry",

  //GoodReceipt
  Purchases_Documents_GoodReceipt_ViewCCost = "Purchases_Documents_GoodReceipt_ViewCCost",
  Purchases_Documents_GoodReceipt_ViewDiscount = "Purchases_Documents_GoodReceipt_ViewDiscount",
  Purchases_Documents_GoodReceipt_ViewTaxOnly = "Purchases_Documents_GoodReceipt_ViewTaxOnly",
  Purchases_Documents_GoodReceipt_ViewTaxCode = "Purchases_Documents_GoodReceipt_ViewTaxCode",
  Purchases_Documents_GoodReceipt_ViewWhsName = "Purchases_Documents_GoodReceipt_ViewWhsName",
  Purchases_Documents_GoodReceipt_ViewUoMEntry = "Purchases_Documents_GoodReceipt_ViewUoMEntry",

  //DownPayments
  Purchases_Documents_DownPayments_ViewCCost = "Purchases_Documents_DownPayments_ViewCCost",
  Purchases_Documents_DownPayments_ViewDiscount = "Purchases_Documents_DownPayments_ViewDiscount",
  Purchases_Documents_DownPayments_ViewTaxOnly = "Purchases_Documents_DownPayments_ViewTaxOnly",
  Purchases_Documents_DownPayments_ViewTaxCode = "Purchases_Documents_DownPayments_ViewTaxCode",
  Purchases_Documents_DownPayments_ViewWhsName = "Purchases_Documents_DownPayments_ViewWhsName",
  Purchases_Documents_DownPayments_ViewUoMEntry = "Purchases_Documents_DownPayments_ViewUoMEntry",
}
